export type ReportType = 'Initial' | 'Intermediate' | 'Final' | 'Monitored' | '_Monitored'

export const reportMessages: { [index in ReportType]: string } = {
    Initial:
        'Congratulations, you’ve taken an important step in taking back your privacy.' +
        ' We’re working very hard to remove your info.' +
        ' Keep in mind, some sites remove your information immediately, others are a little slower.' +
        ' We’re on it, though. We check back and follow up until you’re removed!' +
        '\n\n Remember, we continue to monitor and make certain you don’t reappear.' +
        ' If you do, we take action to get you removed again. This part of the ongoing monitoring service we provide.',
    Intermediate:
        'Great news! You’ve been removed from all but a few of the top 76 people-search sites.' +
        ' We’re still working on the last few. Sometimes it takes a few attempts but we’re on it.' +
        '\n\n Remember, we continue to monitor to make certain you don’t reappear.' +
        ' If you do, we take action to get you removed again. This part of the ongoing monitoring service we provide.',
    Final:
        'Great news! You’ve been removed from each of the top 76 people-search sites.' +
        '\n\n Now we will monitor to make certain you don’t reappear. If you do, we take action to remove you again.' +
        ' This is part of the ongoing monitoring we provide.',
    Monitored:
        'Just a quick note to let you know we checked the top 76 people-' +
        'search sites and the results are listed below.\n\n' +
        'If our report shows Added and Removed, it means the site added you back. Not to worry, we removed you and you are no longer showing.\n',
    _Monitored:
        'Just a quick note to let you know we checked the top 76 people-' +
        'search sites and the results are listed below.\n\n' +
        'If our report shows Added and Removed, it means the site added you back. Not to worry, we removed you and you are no longer showing.\n',
}

const waitForLoad = (page: Window) =>
    new Promise<void>((resolve) => {
        page.addEventListener('load', () => {
            resolve()
        })
    })

export const generateReport = async (
    saveFileName: string,
    replace: { [index: string]: string },
): Promise<void> => {
    const page = window.open(window.location.origin + '/report/ReportTemplate.html', '_blank')
    if (!page) {
        throw new Error('Unable to open print page')
    }
    await waitForLoad(page)
    page.document.title = saveFileName
    const reportDocument = page.document
    reportDocument
        .querySelector('body')
        ?.setAttribute('style', 'padding-top: 0; padding-bottom: 0; -webkit-print-color-adjust: exact')
    // Prevent table cells from splitting across page boundaries
    reportDocument.head.insertAdjacentHTML(
        'beforeend',
        '<style>table { page-break-inside:auto } tr { page-break-inside:avoid; page-break-after:auto }</style>',
    )
        // Phone number must be floated right for proper alignment
        ;[...reportDocument.querySelectorAll('span')]
            .find((e) => e.textContent === '(916) 245-2544')
            ?.setAttribute('style', 'float: right;')

        // White space must be kept from message text
        ;[...reportDocument.querySelectorAll('span')]
            // eslint-disable-next-line no-template-curly-in-string
            .find((e) => e.textContent === '${message}')
            ?.setAttribute('style', 'white-space: pre-line;')

    // Replace placeholders with actual values
    Object.keys(replace).forEach((k) => {
        reportDocument.documentElement.innerHTML = reportDocument.documentElement.innerHTML.replace(
            `\${${k}}`,
            replace[k],
        )
    })

    // Set colors for each status
    const colors: { [index: string]: string } = {
        'Not Listed and Actively Monitoring': '#4caf50',
        'Removed And Actively Monitoring': '#4caf50',
        'Re-Removed and Actively Monitoring': '#95FA8A',
        'Removal Requested': '#ff9800',
        'Awaiting additional info from client': '#f44336',
        Listed: '#f44336',
        // Remove the 'Unknown' status from here
    };
    // Replace 'Unknown' status with 'Awaiting additional info from client'
    const unknownElements = [...reportDocument.querySelectorAll('span')].filter(
        ({ textContent }) => textContent === 'Unknown'
    );
    for (const element of unknownElements) {
        element.textContent = 'Awaiting additional info from client';
    }

    for (const status of Object.keys(colors)) {
        const boxes = [...reportDocument.querySelectorAll('span')].filter(
            ({ textContent }) => textContent === status,
        )
        for (const box of boxes) {
            box.parentElement?.parentElement?.setAttribute('style', `background-color: ${colors[status]}`)
        }
    }

    page.print()
}

export const generateReportAsBase64 = async (
    replace: { [index: string]: string },
): Promise<string> => {
    console.log('Starting generateReportAsBase64 function');

    const response = await fetch(window.location.origin + '/report/ReportTemplate.html', {
        cache: 'no-store',
    });
    const templateHtml = await response.text();

    const parser = new DOMParser();
    const reportDocument = parser.parseFromString(templateHtml, 'text/html');

    const body = reportDocument.querySelector('body');
    if (body) {
        body.setAttribute('style', 'padding-top: 0; padding-bottom: 0; -webkit-print-color-adjust: exact');
    } else {
        console.warn('Body element not found');
    }

    reportDocument.head.insertAdjacentHTML(
        'beforeend',
        '<style>table { page-break-inside:auto } tr { page-break-inside:avoid; page-break-after:auto }</style>',
    );

    const phoneSpan = [...reportDocument.querySelectorAll('span')].find((e) => e.textContent === '(916) 245-2544');
    if (phoneSpan) {
        phoneSpan.setAttribute('style', 'float: right;');
    } else {
        console.warn('Phone number span not found');
    }

    // eslint-disable-next-line no-template-curly-in-string
    const messageSpan = [...reportDocument.querySelectorAll('span')].find((e) => e.textContent === '${message}');
    if (messageSpan) {
        messageSpan.setAttribute('style', 'white-space: pre-line;');
    } else {
        console.warn('Message span not found');
    }

    console.log('Replacing placeholders with actual values');
    Object.keys(replace).forEach((k) => {
        const oldHTML = reportDocument.documentElement.innerHTML;
        reportDocument.documentElement.innerHTML = oldHTML.replace(
            `\${${k}}`,
            replace[k],
        );
    });

    const colors: { [index: string]: string } = {
        'Not Listed and Actively Monitoring': '#4caf50',
        'Removed And Actively Monitoring': '#4caf50',
        'Re-Removed and Actively Monitoring': '#95FA8A',
        'Removal Requested': '#ff9800',
        'Awaiting additional info from client': '#f44336',
        Listed: '#f44336',
    };

    const unknownElements = [...reportDocument.querySelectorAll('span')].filter(
        ({ textContent }) => textContent === 'Unknown'
    );
    for (const element of unknownElements) {
        element.textContent = 'Awaiting additional info from client';
    }

    for (const status of Object.keys(colors)) {
        const boxes = [...reportDocument.querySelectorAll('span')].filter(
            ({ textContent }) => textContent === status,
        );
        for (const box of boxes) {
            const parent = box.parentElement?.parentElement;
            if (parent) {
                parent.setAttribute('style', `background-color: ${colors[status]}`);
            } else {
                console.warn(`Parent element not found for status: ${status}`);
            }
        }
    }

    const htmlContent = reportDocument.documentElement.outerHTML;
    return htmlContent;
};
